import React, { useEffect, useState } from "react"
import $ from "jquery"
import PropTypes from "prop-types"
import { withPrefix, useStaticQuery, graphql } from "gatsby"
import "swiper/swiper-bundle.css"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/ContentBuilder/Header"
import "../components/layout.scss"
import "../scss/style.scss"
import FooterNavMobile from "../components/ContentBuilder/FooterNavMobile"

const Page = ({ children, hideHeader, pageData }) => {
  useEffect(() => {
    $(".select-dropdown").selectpicker({
      style: "",
      dropupAuto: false,
      width: "auto",
    })
    if (typeof pageData !== "undefined") {
      _setPageData(pageData)
    }
  }, [])
  
  const globalConfigRaw = useStaticQuery(graphql`
    {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          turnOnExplore
        }
      }
    }
  `)
  const globalConfig = globalConfigRaw.silverStripeDataObject.UndigitalGlobalConfig
  
  const [scroll, setScroll] = useState(false)
  const [_pageData, _setPageData] = useState({
    pageClass: "",
    bodyClass: "",
    pageTitle: "",
  })
  if (_pageData.bodyClass === "homepage" && window.innerWidth > 991) {
    window.addEventListener("scroll", () => {
      let mainOffset = document.getElementById("main").getBoundingClientRect()
      setScroll(mainOffset.top < 350)
    })
  }
    if(typeof window !== "undefined"){
        const search = window.location.search
        const params = new URLSearchParams(search)
        var utm_fields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term']
        utm_fields.forEach(function(key, value) {
            if(typeof document !== "undefined"){
                let hidden_field = document.getElementsByName(key)
                if(hidden_field.length > 0){
                    if(window.localStorage) {
                        let keyValue = window.localStorage.getItem(key)
                        if(keyValue){
                            hidden_field[0].value = keyValue
                        }
                    }
                }
            }
        })
        params.forEach(function(value, key) {
            var new_values = value
            if (window.localStorage) {
                if(utm_fields.includes(key)){
                    var old_key_values = window.localStorage.getItem(key)
                    if(old_key_values){
                        let old_key_values_arr = old_key_values.split(", ");                                   
                        if(!old_key_values_arr.includes(value)){
                            old_key_values_arr.push(value)
                            new_values = old_key_values_arr.join(", ")   
                        }else{
                            new_values = old_key_values_arr.join(", ")
                        }
                    }
                    window.localStorage.setItem(key, new_values)
                }                
            }
            let hidden_field = document.getElementsByName(key)
            if(hidden_field.length > 0){
                hidden_field[0].value = new_values
            }
        });
    }
  return (
    <>
      <div
        className={`page-wrap ${_pageData.bodyClass} ${
          scroll ? " explore-scroll" : ""
        }`}
      >
        <Header pageData={pageData} siteName={_pageData.bodyClass} />
        <main id="main">{children}</main>

        <Footer />
        <FooterNavMobile />
      </div>

      <Helmet>
        <script src={withPrefix("../../scripts/script.js")} type="text/babel" />
        <script src={withPrefix("../../scripts/filter.js")} type="text/babel" />
      </Helmet>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page